<style lang="less" scoped>
  @import "../../assets/css/variables";
  .customer-content {
    margin: 0 auto;
    width: 92%;
    .el-form-item {
      margin-bottom: 25px !important;
    }
  }

  .order-image-wrapper {
    position: relative;
    height: 120px;
    width: 120px;
    border: 1px solid #bfcbd9;
    border-radius: 4px;
    font-size: 100px;
    line-height: 120px;
    text-align: center;
    color: #ccc;
    cursor: pointer;
    span {
      font-size: 80px;
      line-height: 120px;
      display: block;
    }
    .image-content {
      position: relative;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .image-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: #1c2b36;
      color: #fff;
      font-size: @font-size-base;
    }
  } 
</style>
<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/list' }">客户列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{isEdit?'客户修改':'新增'}}</el-breadcrumb-item>
      
    </el-breadcrumb>

    <div class="page-container">
      <div class="customer-content">
        <el-form ref="customerForm" :rules="rules" :model="customerInfo" label-position='right' label-width="100px">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="单位名称:" prop="name">
                <el-input v-model="customerInfo.name" placeholder="请输入企业名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="单位地址:" prop="address">
                <el-input v-model="customerInfo.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人:" prop="contact">
                <el-input v-model="customerInfo.contact" placeholder="请输入联系人"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="联系人电话:" prop="contactTel">
                <el-input :maxlength="18" v-model="customerInfo.contactTel" placeholder="联系电话"></el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="8">
              <el-form-item label="外链地址:" prop="outChain">
                <el-input :disabled="!customerInfo.isProxy" v-model="customerInfo.outChain" placeholder="请输入更多产品链接地址"></el-input>
              </el-form-item>
            </el-col> -->

            <el-col :span="8">
              <el-form-item label="售后电话:" prop="afterPhone">
                <el-input v-model="customerInfo.afterPhone" placeholder="请输入售后电话"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        
          <!-- <el-form-item label="Logo:" v-if="!isBrand">
            <form id="headImgfileForm" class="none" method="post" enctype="multipart/form-data">
              <input type="file" id="orderImageFile" @change="selectedImageFile" name="upfile" accept="image/*;capture=camera">
            </form>
            <div class="image-content m-t-md clearfix" style="margin-top: -5px;">
              <div @click="triggerSelectFile" class="order-image-wrapper pull-left">
                <span> + </span>
              </div>
              <div @click="delImage()"
                    v-if="tempImage.serverImage && tempImage.serverImage!=''"
                   :class="['order-image-wrapper','pull-left', 'm-b-md']">
                   
                <div class="image-content " :style="{'background-image':'url('+API_ROOT+tempImage.serverImage+')'}"
                     v-loading.body="loading">
                </div>
                <div class="image-close" style="background-color: transparent">
                  <i class="fa fa-times-circle" style="color:#f00;"></i>
                </div>
              </div>
            </div>
          </el-form-item> -->

          <el-form-item label="备　　注:" prop="remarks">
            <el-input type="textarea" v-model="customerInfo.remarks" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item class="text-right m-t-md">
            <el-button type="primary" size="large" @click="onSubmit">保存</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT}from '../../config';
  import {createCustomer, getAllCustomers, getCustomerById, updateCustomerInfo} from '../../services/customer';
  import {getAllRole} from '../../services/role';
  import {mapGetters, mapActions} from "vuex";
  import {Notification} from "element-ui";
  import {getFileUrl} from "../../utils/tools/tools";
  import {uploadImageFile} from '../../services/common';
  import {valiInput,valiSelect} from "../../utils/validator/element";
  export default{
    data(){
      const valiContactTel = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入联系人电话'));
        }
        else {
          if (!/(^(\d{3,4}-)?\d{7,10})+(\-[0-9]{1,4})?$|^(1[3|4|5|6|7|8]\d{9}$)/.test(value)) {
            return callback(new Error('请输入正确的联系人电话'));
          } else {
            callback();
          }
        }
      };
     
      const valiProxyType = (rule, value, callback) => {
        if (value === true || value === false) {
          callback();
        } else {
          return callback(new Error('不能为空'));
        }
      };
      const valiLink = (rule, value, callback) => {
        if (value && !/^(http|https):\/\/.+/.test(value)) {
          callback(new Error('网址输入不正确'));
          } else {
          callback();
        }
      };
      return {
        loading: false,
        disabled: false,
        isBrand: false,
        tempImage: {},
        API_ROOT,
        customerInfo: {
          name: '',
          contact: '',
          contactTel: '',
          isProxy: '',
          proxy: '',
          proxyName: null,
          address: '',
          outChain: '',
          storeAddress: '',
          remarks: '',
          logoUrl: ''
        },
        brandList: [],
        rules: {
          name: [
            {validator: valiInput, trigger: 'blur'}
          ],
          contact: [
            {validator: valiInput, trigger: 'blur'}
          ],
          contactTel: [
            {validator: valiContactTel, trigger: 'change'}
          ],
          address: [
            {validator: valiInput, trigger: 'blur'}
          ],
          isProxy: [
            {validator: valiProxyType, trigger: 'change'}
          ],
          remarks: [
            {require: false, min: 2, max: 30, message: '长度在2到30个字符', trigger: 'blur'}
          ],
          afterPhone: [
            {validator: valiContactTel, trigger: 'blur'}
          ],
          // outChain: [
          //   {validator: valiLink, trigger: 'blur'}
          // ],
          
        }
      }
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      ),
      isEdit() {
        return !!this.customerInfo.id
      }
    },
   
    methods: {
      /**
       * 权限判断
       */
      checkPermission() {
        const userRole = this.user.roleCode;
        if (!(/Admin/.test(userRole))) {
          this.disabled = true;
          this.customerInfo.proxyCustomer = this.user.customerName;
        }
      },
      /**
       * @param index 在imageList中的下标
       */
      delImage(){
        this.tempImage = {};
        // this.$set(this.tempImage, 'serverImage', '');
        // console.log()
      },
      checkIsproxy() {
        if (this.customerInfo.isProxy) {
          this.customerInfo.proxy = '';
          // alert(1)
        }
      },
      /**
       * 获取所有的品牌商
       */
      async queryCustomers(){
        const responseData = await getAllCustomers(null, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.brandList = responseData.data.list || [];
          if(this.brandList.length) {
            this.customerInfo = this.brandList[0]
            console.log(this.customerInfo);
          }
        }
        this.loaded = true;
      },

      /**
       * [更新客户信息]
       * @return {[type]} [description]
       */
      async updateCustomerInfo(){
        let customerInfoData = null;
        const customer = Object.assign({}, this.customerInfo);
        if (customer.isProxy) {
          customer.proxy = null;
          customer.proxyName = null;
        }
        if (this.isEdit) {
          customerInfoData = await updateCustomerInfo(customer);
        } else {
          delete customer.id;
          customerInfoData = await createCustomer(customer);
        }
        if (customerInfoData.errorCode === 0) {
          Notification.success({
            title: '成功',
            message: this.isEdit?'修改成功':'新增成功'
          });
          this.customerInfo = customerInfoData.data
        } else {
          Notification.error({
            title: '失败',
            message: customerInfoData.data.errorMessage
          });
        }
        return false;
      },
      /**
       * 添加proxyname
       */
      async addProxyName() {
        const id = this.customerInfo.proxy;
        console.log("id"+ id)
        // console.log(this.brandList)
        if (id) {
          const res = await getCustomerById(id);
          // this.customerInfo.proxyName = res && res.data 
          // && res.data.name;
          //console.log(this.customerInfo.proxyName)
          this.updateCustomerInfo();

        } else {
          this.updateCustomerInfo();
        }
      },
      /**
       * 获取相关客户信息；
       */
      async getCustomerInfo(id) {
        const res = await getCustomerById(id);
        if (res && res.errorCode === 0 && res.data) {
          // alert(2)
          this.customerInfo = res.data;
          this.tempImage.serverImage = res.data.logoUrl;
          // console.log(this.customerInfo)
        }
      },

      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit(){
        this.$refs.customerForm.validate((valid) => {
          
          if (valid) {
            if(this.tempImage.serverImage) {
              this.customerInfo.logoUrl = this.tempImage.serverImage;
            } else {
              this.customerInfo.logoUrl = '';
            }
            this.updateCustomerInfo();
          }
        });
      },
      /**
       * 触发文件选择
       */
      triggerSelectFile(){
        document.querySelector('#orderImageFile').click();
      },

      /**
       * 选中
       */
      selectedImageFile($event){
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if ((file.size / 1024 / 1024 / 10) > 1) { //10M
            Notification.error({
              title: '错误',
              message: '请选择一个小于10M图片'
            });
            return;
          }
        } else {
          Notification.error({
            title: '错误',
            message: '请选择一个图片文件'
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        const fileImageUrl = getFileUrl('#orderImageFile');
        let image = new Image();
        image.src = fileImageUrl;
        this.loading = true;
        image.onload = () => {
          this.tempImage = {fileImage: fileImageUrl, serverImage: ''};
          this.uploadImageFile(file);
        };
      },

      /**
       * 上传图片
       * @param imageFile Blob file
       */
      async uploadImageFile(imageFile){
        const aafile = new File([imageFile], "cnm.png");
        const uploadData = await uploadImageFile(aafile);
        if (uploadData && uploadData.errorCode === 0
          && uploadData.data && uploadData.data.result) {
          this.tempImage.serverImage = uploadData.data.fpicurl;
          this.loading = false;
          document.getElementById("headImgfileForm").reset();
        }
      },

    },

    created() {
      this.queryCustomers();
    },
  }
</script>
